<template>
  <div class="wrapper">
    <div class="title">
      <img src="../../assets/img/album/nav_icon_back.svg" alt="" @click="close">
      <div class="name">{{ $t('lang.about') }}</div>
    </div>
    <div class="header">
      <img src="../../assets/img/about/logo.png" alt="">
      <div class="name">ShowMoment</div>
    </div>
    <div class="protocols">
      <div class="title" @click="expandClick">
        <span>使用的协议</span>
        <div class="right">
          <span>{{ !isExpand ? '展开' : '收起' }}</span>
          <img src="../../assets/img/album/nav_icon_back.svg" alt="" :class="{'expand': !isExpand, 'collapse': isExpand}">
        </div>
      </div>
      <div v-for="protocol in protocols" @click="openLink(protocol.link)" v-show="isExpand">
        <span>{{protocol.name}}</span>
        <img src="../../assets/img/album/nav_icon_back.svg" alt="">
      </div>
    </div>
    <div class="intro">
      <div class="tabs">
        <div :class="{'active': selected === 0}">
          <span @click="handleClick(0)">收费说明</span>
          <div></div>
        </div>
        <div :class="{'active': selected === 1}">
          <span @click="handleClick(1)">收入说明</span>
          <div></div>
        </div>
      </div>
      <template v-if="selected === 0">
        <div>
          <div class="item">发帖</div>
          <div class="fee">2000Sat (平台收取) + 图片上链费5% (平台收取) + 矿工费</div>
        </div>
        <div>
          <div class="item">评论</div>
          <div class="fee">2000Sat (作者收取) + 总收费20% (平台收取) + 矿工费</div>
        </div>
        <div>
          <div class="item">收藏</div>
          <div class="fee">2000Sat (作者收取) + 总收费20% (平台收取) + 矿工费</div>
        </div>
        <div>
          <div class="item">点赞</div>
          <div class="fee">2000Sat (作者收取) + 总收费20% (平台收取) + 矿工费</div>
        </div>
        <div>
          <div class="item">关注</div>
          <div class="fee">2000Sat (作者收取) + 矿工费</div>
        </div>
        <div>
          <div class="item">拉黑</div>
          <div class="fee">矿工费</div>
        </div>
        <div>
          <div class="item">打赏</div>
          <div class="fee">打赏金额 (作者收取) + 矿工费</div>
        </div>
      </template>
      <template v-else>
        <div>
          <div class="item">收到评论</div>
          <div class="fee">2000Sat</div>
        </div>
        <div>
          <div class="item">收到收藏</div>
          <div class="fee">2000Sat</div>
        </div>
        <div>
          <div class="item">收到关注</div>
          <div class="fee">2000Sat</div>
        </div>
        <div>
          <div class="item">收到点赞</div>
          <div class="fee">2000Sat</div>
        </div>
        <div>
          <div class="item">收到打赏</div>
          <div class="fee">打赏金额</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      protocols: [
        {
          name: `PayLike ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/0e348f9c9b1bd17c9fe453aab3576d1b5cebe84753a134e03df5b7cd2a973cff'
        },
        {
          name: `PayComment ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/c02115ebc96cbedd8f0f0bf9898506cc2e6ba585b88638b99016f1d3a68cdab4'
        },
        {
          name: `PayFollow ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/7abec68343f492d26c6518401d81bac645ddbe9c860225e41e4e84738236a4b5'
        },
        {
          name: `SimpleBlacklist ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/f902003129654742c1b3c7375f4913de497e5fd662dd4eb65be19108c1195b72'
        },
        {
          name: `SimpleArticleDonate ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/e4d22275bcee6bb9511ef0ec3767e605a7b60d1cd7000fb3e9f22f3b7fa68e6f'
        },
        {
          name: `SimplePhotoShare ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/cc732fb32f45f3b298606fa0ed99efe3483e6f49631a441220a1d3aaf5193da9'
        },
        {
          name: `SimplePhotoAlbum ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/80fba026d481d0134aa95441ab7fe921766389bb8e631f0287778d8c75edc6f4'
        },
        {
          name: `metaphotofolder ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/4e7efbd71487b7054bd258223ebb9adf38d2af943edbe1af00a267fed239790c'
        },
        {
          name: `metaphotoItem ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/5e90c7a47810eb6f934841c6863fd2f995b1552b777b3c56d8573f392b578df8'
        },
        {
          name: `MetaFile ${this.$t('lang.protocol')}`,
          link: 'https://www.metaprotocols.com/protocol_details/f5dfd113de52268d106f8a37a6e90811e3a1e6e728c663cf224d73757bc31caf'
        }
      ],
      selected: 0,
      isExpand: false
    }
  },
  methods: {
    openLink(link) {
      location.href = link
    },
    close() {
      this.$router.back()
    },
    handleClick(index) {
      this.selected = index
    },
    expandClick() {
      this.isExpand = !this.isExpand
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 600Px;
  margin: 0 auto;
  > .title {
    height: 48px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 1000;
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    .name {
      font-size: 18px;
      color: #303133;
      margin-left: 8px;
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 219px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 12px;
      margin-top: 31px;
    }
    .name {
      color: #000000;
      font-size: 18px;
      margin-top: 7px;
    }
  }
  .protocols {
    .title {
      font-size: 18px;
      padding-left: 15px;
      .right {
        font-size: 13px;
        color: #909399;
        span {
          margin-right: 5px;
        }
        .expand {
          transform: rotate(270deg);
        }
        .collapse {
          transform: rotate(90deg);
        }
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      background-color: #FFFFFF;
      color: #303133;
      font-size: 14px;
      padding: 0 20px;
      cursor: pointer;
      img {
        transform: rotate(180deg);
      }
    }
  }
  .intro {
    margin-top: 20px;
    padding-bottom: 50px;
    background-color: #F7F9FC;
    .tabs {
      background-color: #FFFFFF;
      height: 48px;
      font-size: 18px;
      color: #909399;
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        span {
          margin: 0 15px 4px;
          cursor: pointer;
        }
        div {
          width: 20px;
          height: 5px;
          border-bottom: 3Px solid #FFFFFF;
          margin: 0 auto;
        }
        &.active {
          span {
            margin: 0 15px 4px;
            cursor: pointer;
            color: #303133;
          }
          div {
            width: 20px;
            height: 5px;
            border-bottom: 3Px solid #7584FF;
            margin: 0 auto;
          }
        }
      }
    }
    .item {
      color: #5173B9;
      font-size: 13px;
      height: 34px;
      line-height: 34px;
      padding-left: 15px;
    }
    .fee {
      background-color: #FFFFFF;
      height: 48px;
      line-height: 48px;
      padding-left: 15px;
      font-size: 13px;
    }
  }
}
</style>