var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/img/album/nav_icon_back.svg"),
          alt: "",
        },
        on: { click: _vm.close },
      }),
      _c("div", { staticClass: "name" }, [
        _vm._v(_vm._s(_vm.$t("lang.about"))),
      ]),
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "protocols" },
      [
        _c("div", { staticClass: "title", on: { click: _vm.expandClick } }, [
          _c("span", [_vm._v("使用的协议")]),
          _c("div", { staticClass: "right" }, [
            _c("span", [_vm._v(_vm._s(!_vm.isExpand ? "展开" : "收起"))]),
            _c("img", {
              class: { expand: !_vm.isExpand, collapse: _vm.isExpand },
              attrs: {
                src: require("../../assets/img/album/nav_icon_back.svg"),
                alt: "",
              },
            }),
          ]),
        ]),
        _vm._l(_vm.protocols, function (protocol) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpand,
                  expression: "isExpand",
                },
              ],
              on: {
                click: function ($event) {
                  return _vm.openLink(protocol.link)
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(protocol.name))]),
              _c("img", {
                attrs: {
                  src: require("../../assets/img/album/nav_icon_back.svg"),
                  alt: "",
                },
              }),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "intro" },
      [
        _c("div", { staticClass: "tabs" }, [
          _c("div", { class: { active: _vm.selected === 0 } }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleClick(0)
                  },
                },
              },
              [_vm._v("收费说明")]
            ),
            _c("div"),
          ]),
          _c("div", { class: { active: _vm.selected === 1 } }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleClick(1)
                  },
                },
              },
              [_vm._v("收入说明")]
            ),
            _c("div"),
          ]),
        ]),
        _vm.selected === 0
          ? [
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6),
              _vm._m(7),
            ]
          : [_vm._m(8), _vm._m(9), _vm._m(10), _vm._m(11), _vm._m(12)],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/about/logo.png"), alt: "" },
      }),
      _c("div", { staticClass: "name" }, [_vm._v("ShowMoment")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("发帖")]),
      _c("div", { staticClass: "fee" }, [
        _vm._v("2000Sat (平台收取) + 图片上链费5% (平台收取) + 矿工费"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("评论")]),
      _c("div", { staticClass: "fee" }, [
        _vm._v("2000Sat (作者收取) + 总收费20% (平台收取) + 矿工费"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("收藏")]),
      _c("div", { staticClass: "fee" }, [
        _vm._v("2000Sat (作者收取) + 总收费20% (平台收取) + 矿工费"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("点赞")]),
      _c("div", { staticClass: "fee" }, [
        _vm._v("2000Sat (作者收取) + 总收费20% (平台收取) + 矿工费"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("关注")]),
      _c("div", { staticClass: "fee" }, [
        _vm._v("2000Sat (作者收取) + 矿工费"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("拉黑")]),
      _c("div", { staticClass: "fee" }, [_vm._v("矿工费")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("打赏")]),
      _c("div", { staticClass: "fee" }, [
        _vm._v("打赏金额 (作者收取) + 矿工费"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("收到评论")]),
      _c("div", { staticClass: "fee" }, [_vm._v("2000Sat")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("收到收藏")]),
      _c("div", { staticClass: "fee" }, [_vm._v("2000Sat")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("收到关注")]),
      _c("div", { staticClass: "fee" }, [_vm._v("2000Sat")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("收到点赞")]),
      _c("div", { staticClass: "fee" }, [_vm._v("2000Sat")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "item" }, [_vm._v("收到打赏")]),
      _c("div", { staticClass: "fee" }, [_vm._v("打赏金额")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }